<template>
  <div class="mainform">
    <!--  -->
    <div class="mainHeader">索赔通知单 {{form.qualityCode}}
        <span @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <div class="header">基本信息 </div>
      <el-form class="content bascform" ref="BasicForm" :model="form" label-width="130px">
          <div class="col col4">
            <el-form-item label="索赔单号">
              <span class="onlyText">
                {{form.qualityCode}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔类型">
              <span class="onlyText">
                {{form.claimType==1?'来料检验索赔':form.claimType==2?'售后索赔':form.claimType==3?'生产过程索赔':'行政索赔'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
              <span class="onlyText">
                {{form.companyName}}
              </span>
            </el-form-item>
          </div>
        <div class="col col4">
            <el-form-item label="索赔来源">
              <span class="onlyText">
                {{form.claimSource==1?'来料':form.claimSource==2?'售后':form.claimSource==3?'生产线':'研发'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="采购员">
              <span class="onlyText">
                {{form.buyerName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="要求反馈日期">
              <span class="onlyText">
                {{form.feedbackDate}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4 notice-file">
            <el-form-item label="质量责任通知单">
              <el-upload
                disabled
                :action="actionURL"
                :on-preview="handlePreview"
                multiple
                :file-list="form.fileInfoList"
              >
              </el-upload>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="币种">
              <span class="onlyText">
                {{form.currency==1?'RMB':form.currency==2?'USD':form.currency}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔说明">
              <span class="onlyText">
                {{form.claimDesc}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="索赔总额">
              <span class="onlyText">
                {{form.claimMoney}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="状态">
              <span class="onlyText">
                {{form.status==null?'待我确认':form.status==1?'待我确认':form.status==2?'待客户确认':form.status==3?'待确认索赔':form.status==4? '已完成':''}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" :class="form.status==null||form.status==1 ? '' : 'notice-file' ">
            <el-form-item label="索赔凭证">
              <el-upload
                ref="upload2"
                name="file"
                :disabled="form.status !== null && form.status !== 1"
                :auto-upload="true"
                :action= 'postUrl'
                :on-preview="downBylob"
                :on-exceed="handleExceed"
                :on-remove="handleRemoveQsd"
                :on-success="handSuccess"
                :on-error="handError"
                :headers="tokenHeader"
                :file-list="upReturnData"
              >
                <span v-if="form.status==null||form.status==1">付款凭证：</span><el-button size="mini" type="primary" plain v-if="form.status==null||form.status==1">上传附件</el-button>
              </el-upload>
            </el-form-item>
          </div>
      </el-form>
      <div class="action" v-if="form.status==null||form.status==1">
        <!-- <el-button class="submit" type="primary" size="medium" @click="appeal">申诉</el-button> -->
        <el-button class="submit" type="primary" size="medium" @click="querySub">确认</el-button>
      </div>
    </div>
        <dialog-box  ref="dialogbox" dialogWidth="60%" :dialogShow="dialogShow" @handleClose='handleClose'
      title="填写申诉意见" componentName="Complaintsopinion" @affirm='affirm'
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'affirm', size: 'mini' }]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, baseURL } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import { fileHandle } from '@/assets/js/FileAction.js'
export default {
  components: Component.components,
  name: 'AddAdviseReport',
  props: [],
  data: function () {
    return {
      form: { file: [] },
      fileList: [],
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      dialogShow: false,
      id: '',
      upReturnData: [],
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/file/m/file/uploadToServer'
    }
  },
  mounted () {
    this.id = this.$route.query.id
    request('/api/quality/claim/getById/' + this.id, 'get').then((res) => {
      if (res.code === '200') {
        this.form = res.data
        if (res.data.status !== null) {
          this.upReturnData = res.data.payList || []
        }
      }
    })
  },
  created () {

  },
  methods: {
    /**
     * 确认
     * 修改内容：新增付款凭证附件
     * 修改日期：2023-08-07
     * 作者：高天滨
     */
    querySub () { // 确认
      if (this.upReturnData.length < 1) {
        this.$message.error('请上传付款凭证')
        return false
      }
      const obj = {
        id: this.id,
        // status: 4 // 完成 修改前
        status: 3, // 完成 修改后 待确认索赔
        payList: this.upReturnData // 新增付款凭证附件
      }
      console.log('obj...', obj)
      request('/api/quality/claim/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/QualitySynergy/accountability')
        }
      })
    },
    appeal () { // 申诉
      this.dialogShow = true
    },
    affirm () {

    },
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
    },
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    },
    downBylob (file) {
      fileHandle.handlePreview(file)
    },
    handleExceed (file) {

    },
    handError (file) {

    },
    handleRemoveQsd (file, list) {
      console.log(file)
      this.upReturnData = list
      request('/api/file/m/file/delFile/' + file.id, 'get')
    },
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(item => {
          this.upReturnData.push(item)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
    :deep(.el-form){
      .notice-file {
        .el-upload.el-upload--text{
          display: none;
        }
        .el-upload-list__item:first-child {
          margin-top: 0;
        }
        .el-upload-list__item-name {
          padding-top: 5px;
        }
      }
  }
</style>
